import React from 'react';
import { graphql } from 'gatsby';

import Link from '@mui/material/Link';

import CallToAction from '../../sections/CallToAction';
import ContactDetails from '../../sections/contactDetails';
import Episodes from '../../sections/episodes';
import Hero, {
  PlayButton,
  Text,
  Tagline,
  Title,
} from '../../sections/hero/hero';
import Layout from '../../components/layout/layout';
import Statement from '../../sections/statement';

import shareImage from '../../assets/images/podcast-social-sharing.jpg';

export default function Podcast(props) {
  const backgroundImage = props.data.file.childImageSharp.gatsbyImageData;
  const recentEpisodeUrl =
    props.data.allPodcastRssFeedEpisode.edges[0].node.item.enclosure.url;

  return (
    <Layout
      title="Digital Insurance Podcast"
      tagline="Gespräche zur digitalen Transformation in der Versicherungswirtschaft"
      description="Mit Managern etablierter Unternehmen rede ich über die Herausforderungen der digitalen Transformation in der Versicherungswirtschaft. Neue Ausgaben erscheinen am ersten Donnerstag im Monat."
      shareImage={shareImage}
      pathname={props.location.pathname}
    >
      <Hero backgroundImage={backgroundImage}>
        <Tagline>Digital Insurance Podcast</Tagline>
        <Title>
          Gespräche zur digitalen Transformation in der Versicherungswirtschaft
        </Title>
        <Text>
          {' '}
          Neue Ausgaben erscheinen am ersten Donnerstag im Monat hier oder bei{' '}
          <Link
            href="https://podcasts.apple.com/de/podcast/digital-insurance-podcast/id1498593777"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple
          </Link>
          ,{' '}
          <Link
            href="https://podcasts.google.com/?feed=aHR0cDovL3BvZGNhc3QtYWRtaW4uZmlyZWJhc2VhcHAuY29tL2ZlZWQvMlQ5YVZxRUpodmQxRmNrMnZWUHAv"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google
          </Link>
          ,{' '}
          <Link
            href="https://open.spotify.com/show/0Oh8uJFbxX066lBPdCGehW"
            target="_blank"
            rel="noopener noreferrer"
          >
            Spotify
          </Link>{' '}
          und Co.
        </Text>
        <PlayButton src={recentEpisodeUrl}>
          Aktuelle Ausgabe abspielen
        </PlayButton>
      </Hero>
      <Statement
        title="Der Podcast für den digitalen Wandel in der Versicherungswirtschaft"
        text={[
          'Im Digital Insurance Podcast spricht Jonas Piela mit Vorständen deutscher Versicherer über Themen der digitalen Transformation. In einem Gespräch unter Gleichgesinnten erarbeitet er gemeinsam mit seinen Gästen Herausforderungen, Trends und Techniken des digitalen Wandels für die Versicherungswirtschaft.',
          'Auf unserer Podcast-Bühne sprechen authentischen Thoughtleader der Branche über für sie und für dich, relevante Prozesse der digitalen Transformation.',
        ]}
      />
      <Episodes
        title="Höre jetzt den Digital Insurance Podcast"
        linkToPodcastEpisodes
        limit="3"
      />
      <CallToAction
        title="Du willst Gast im Podcast sein?"
        text="
            Wenngleich wir viele Anfragen erhalten, suchen wir laufend spannende Gesprächspartner.
            Am liebsten reden wir über starke Meinungen, die für den digitalen Wandel in der Versicherungswirtschaft von Bedeutung sind.
            Lass uns einen Termin vereinbaren und gemeinsam schauen, ob dein Thema für unsere Zuhörer interessant ist!"
        label="Termin vereinbaren"
        href="https://calendar.app.google/nor372ehN7T1wF1x6"
        target="_blank"
      />
      <ContactDetails
        title="Fragen, Kritik oder Anregungen? Wir wollen es hören!"
        profiles={props.data.team.nodes}
      />
    </Layout>
  );
}
export const query = graphql`
  query PodcastImageQuery {
    file(relativePath: { eq: "backgrounds/bg-podcast.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    allPodcastRssFeedEpisode(
      sort: { fields: item___isoDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          item {
            enclosure {
              url
            }
          }
        }
      }
    }
    team: allTeamJson(
      filter: { unit: { eq: "podcast" } }
      sort: { order: ASC, fields: prio }
    ) {
      nodes {
        name
        bio
        focus
        city
        country
        bookingLink
        linkedin
        email
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
